import React from "react"
import "./slider.scss"
import bedImg from "../assets/imgs/bed.svg"
import couchImg from "../assets/imgs/couch.svg"
import guestsImg from "../assets/imgs/guests.svg"
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useIntl, Link } from "gatsby-plugin-intl"
import { ReactComponent as Arrow } from "../assets/imgs/arrow.svg"
import Img from "gatsby-image"
import { useRef } from "react"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " arrow"}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <Arrow />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " arrow"}
      style={{ ...style, display: "block", transform: "rotate(180deg)" }}
      onClick={onClick}
    >
      <Arrow />
    </div>
  )
}

const Slider = props => {
  const sliderRef = useRef(null)

  function handleKeyDown(event) {
    //arrow right
    if (event.keyCode == 39) {
      sliderRef.slickNext()
      console.log("next slider")
    }
    //arrow left
    if (event.keyCode == 37) {
      sliderRef.slickPrev()
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const slike = props.slike.edges

  const intl = useIntl()

  return (
    <div>
      <Slick
        {...settings}
        ref={sliderRef}
        onKeyDown={e => handleKeyDown(e)}
        tabIndex={0}
      >
        {props.rooms.map((room, index) => (
          <Link to={`/jedinica-${room.id}`} key={index} className="card-slide">
            <div className="slide-container">
              <div className="image-container">
                {slike.map(
                  (slika, i) =>
                    slika.node.relativeDirectory === `jedinica-${room.id}` && (
                      <Img
                        key={i}
                        fluid={{
                          ...slika.node.childImageSharp.fluid,
                          sizes: "(min-width: 768px) 400px, 100vw",
                          aspectRatio: 1.503759398496241,
                        }}
                      />
                    )
                )}
              </div>
              <div className="data row">
                {room.beds["bracni"] ? (
                  <div>
                    <p>{room.beds["bracni"]}</p>
                    <img src={bedImg} alt="number of beds" />
                  </div>
                ) : (
                  ""
                )}
                {room.beds["kauc"] ? (
                  <div>
                    <p>{room.beds["kauc"]}</p>
                    <img src={couchImg} alt="number of couches" />
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <p>{room.guests}</p>
                  <img src={guestsImg} alt="max guests" />
                </div>
              </div>
              <div className="naslov" style={{ fontWeight: "bold" }}>
                <p>{intl.formatMessage({ id: `jedinica${room.id}.naslov` })}</p>
              </div>
            </div>
          </Link>
        ))}
      </Slick>
    </div>
  )
}

export default Slider
